'use strict';

/* globals window $ */

var loadUtils = true,
    updateCountryNames = true;

/**
 * manage phone numbers international codes
 */
function init($country, $phone, initialCountryCode) {
    if (loadUtils) {
        window.intlTelInputGlobals.loadUtils('https://' + window.location.host + window.Urls.intlTelInputUtils).then(function () {
            $phone.trigger('intltelutilsloaded');
        });
        loadUtils = false;
    }

    var iti, countryCode, phone;

    // already initialized
    if ($phone.parent().hasClass('iti')) {
        // force the country refresh
        iti = window.intlTelInputGlobals.getInstance($phone[0]);
        countryCode = $country.val() || initialCountryCode;
        phone = $.trim($phone.val());
        if (countryCode !== null && countryCode !== '' && phone === '') {
            iti.setCountry(countryCode.toLowerCase());
        }
        return;
    }

    if (updateCountryNames) {
        var countryData = window.intlTelInputGlobals.getCountryData();
        $.each(countryData, function(i, country) {
            var translatedName = $.trim($country.find('option[value="' + country.iso2.toUpperCase() + '"]').text());
            if (translatedName !== null && translatedName !== '') {
                var tmp = country.name.split('(');
                country.name = translatedName + (tmp.length == 2 ? ' (' + tmp[1].toUpperCase() : '');
            }
            else {
                country.name = country.name.toUpperCase();
            }
        });

        updateCountryNames = false;
    }

    var initialCountry = $country.val() || initialCountryCode,
        preferredCountries = [];
    initialCountry = initialCountry ? initialCountry.toLowerCase() : '';

    if (initialCountry) {
        preferredCountries.push(initialCountry);
    }

    // for Canary Islands, Ceuta and Melilla, use the Spain data for the phone
    if (initialCountry == 'ic' || initialCountry == 'ea') {
        initialCountry = 'es';
    }

    if ($phone.length > 0) {
        // https://github.com/jackocnr/intl-tel-input/tree/v20.0.0?tab=readme-ov-file#initialisation-options
        iti = window.intlTelInput($phone[0], {
            countrySearch: false,
            autoPlaceholder: 'aggressive',
            initialCountry: initialCountry,
            nationalMode: true,
            preferredCountries: preferredCountries,
            hiddenInput: function(telInputName) {
                return {
                    phone: telInputName
                }
            }
        });
        iti.promise.then(function () {
            if ($phone.parent().hasClass('iti')) {
                $phone.attr('name', function (i, attr) {return attr + '_intltel';});
            }

            $country.on('change selectmenuchange', function () {
                countryCode = $country.val();
                phone = $.trim($phone.val());
                if (countryCode !== '' && phone === '') {
                    iti.setCountry(countryCode.toLowerCase());
                }
            });

            $phone.rules('add', {phoneintl: []});
        });

        return iti;
    }
}

exports.init = init;
