'use strict';

/* globals window FormData $ setTimeout */

var cache = {};

/**
 * Initialize donation form step 1 cache
 * @param {jQuery Element} $donationWrapper - donation wrapper jQuery element
 */
var initCache = function ($donationWrapper) {
    cache.$donationWrapper = $donationWrapper;
    cache.$form = cache.$donationWrapper.find('#js-donation_step1');
    cache.$selectAmount = cache.$form.find('.js-donation_selectamount');
    cache.$freeAmount = cache.$form.find('.js-donation_freeamount');
    cache.$submit = cache.$form.find('.js-donation_submit');
    cache.$errorWrapper = cache.$donationWrapper.find('.js-error_message');
    cache.$amount = cache.$form.find('.js-donation-amount');
    cache.$donationStep1loader = cache.$donationWrapper.find('.js-donation_step1-loader');
    cache.$flashmessage = cache.$form.find('.js-flash_message');

    // cache form validator
    cache.validator = cache.$form.validate();
    // global variable required for flash message
    cache.flashmessage = false;
};

var submitAction = function (e) {
    e.preventDefault();

    var isValid = true;

    // remove flash message error
    flashMessageCleanup();

    // remove any previous error message
    cache.$amount.removeClass('gl_has-error');
    cache.$errorWrapper.html('');

    // security; disable submit button
    cache.$submit.attr('disabled', true);

    // disabled donation-step1 form
    cache.$form.addClass('gl_form-disabled');
    window.vbqUtils.loader(true, false, cache.$donationStep1loader);

    // validate the form
    if (!cache.$form.valid()) {
        isValid = false;
    }

    var selectedAmount = cache.$selectAmount.filter(':checked').val(),
        freeAmount = cache.$freeAmount.val(),
        amount = selectedAmount || freeAmount;

    // check if an amount was selected / set
    if (!amount) {
        // show error message
        var amountError = {};
        amountError[cache.$freeAmount.attr('name')] = window.Resources.AMOUNT_REQUIRED;
        cache.validator.showErrors(amountError);
        cache.$amount.addClass('gl_has-error');

        isValid = false;
    }

    if (!isValid) {
        // enable the submit button
        cache.$submit.attr('disabled', false);

        // enable donation-step1 form
        cache.$form.removeClass('gl_form-disabled');
        window.vbqUtils.loader(false, false, cache.$donationStep1loader);

        return;
    }

    var action = cache.$form.attr('action'),
        formData = new FormData(cache.$form.get(0));

    formData.append(cache.$submit.attr('name'), 'submit');

    $.ajax({
        url: action,
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
    })
    .done(function (response) {
        if (!response.success || !response.redirectUrl) {
            // enable the submit button
            cache.$submit.attr('disabled', false);

            // enable donation-step1 form
            cache.$form.removeClass('gl_form-disabled');
            window.vbqUtils.loader(false, false, cache.$donationStep1loader);

            // error messages will be shown below each field which has an error
            if (response.errorData) {
                cache.validator.showErrors(response.errorData);
                return;
            }

            // global message
            var errorMessage = response.message || window.Resources.NEWSLETTER_SUBSCRIBE_ERROR;
            cache.$errorWrapper.html(errorMessage);
            return;
        }

        // redirect the page to step 2
        window.location.href = response.redirectUrl;
    })
    .fail(function () {
        // enable the submit button
        cache.$submit.attr('disabled', false);

        // enable donation-step1 form
        cache.$form.removeClass('gl_form-disabled');
        window.vbqUtils.loader(false, false, cache.$donationStep1loader);

        // show a general error message
        cache.$errorWrapper.html(window.Resources.TECHNICAL_ERROR);
    });
};

/**
 * Action to do on free amount value input / change
 * @param {jQuery Object} e - event object
 * @returns {Boolean} true if the current char is not a dot
 */
var keyPressAction = function (e) {
    // uncheck any selected amount, if any
    cache.$selectAmount.prop('checked', false);

    // remove error class if no errors
    if (cache.$freeAmount.valid() && !cache.flashmessage) {
        cache.$amount.removeClass('gl_has-error');
    }

    // Don't allow dot char
    var keycode = e.keyCode,
        notAllowedKeyCodes = [
            110, // dot . ( numpad )
            107, // + ( numpad )
            189, // dash - ( keyboard )
            109, // minus - ( keyboard numpad )
            190, // dot ( keyboard )
            188, // comma ,
            32, // tab on mobile qwerty keyboard
            0, // dash - on mobile qwerty keyboard,
            69 // e - on the keyboard
        ];

    // trigger flashmessage event if the key pressed is dot or comma
    if ([110, 190, 188].indexOf(keycode) > -1) {
        cache.$freeAmount.trigger('flashmessage');
    }

    return notAllowedKeyCodes.indexOf(keycode) === -1;
};

/**
 * Action to do on select amount for donation
 * @param {jquery Object} e - event object
 */
var onSelectAmountAction = function (e) {
    e.preventDefault();

    // Empty free amount value if it has any
    cache.$freeAmount.val('');
    // remove any field error if exists
    var amountError = {};
    amountError[cache.$freeAmount.attr('name')] = '';
    cache.validator.showErrors(amountError);
    cache.$amount.removeClass('gl_has-error');

    // uncheck any previous selected amount
    cache.$selectAmount.prop('checked', false);

    // check current clicked amount element
    $(e.currentTarget).prop('checked', true);
};

var flashMessageCleanup = function () {
    if (cache.flashmessage) {
        cache.flashmessage = false;
        cache.$flashmessage.addClass('hidden');
        cache.$amount.removeClass('gl_has-error');
    }
};

var flashMessageAction = function () {
    // do nothing if flash message is visible
    if (cache.flashmessage) {
        return;
    }

    cache.flashmessage = true;
    // show flash message
    cache.$flashmessage.removeClass('hidden');
    cache.$amount.addClass('gl_has-error');

    setTimeout(function () {
        flashMessageCleanup();
    }, 3000);
};

/**
 * Initialize donation form step 1 events
 */
var initEvents = function () {
    cache.$submit.on('click', submitAction);

    // don't allow any dot chars in freeamount ( type = number )
    cache.$freeAmount.on('keydown', keyPressAction);
    cache.$freeAmount.on('flashmessage', flashMessageAction);

    cache.$selectAmount.on('change', onSelectAmountAction);

    // validate freeamount field
    cache.$freeAmount.valid();
};

module.exports.init = function ($donationWrapper) {
    initCache($donationWrapper);
    initEvents();
};
