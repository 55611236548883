'use strict';

/* globals $ window */

/**
 * Set defaults for validator
 */
var defaults = $.validator.defaults;

defaults.errorClass = 'gl_error-message';
defaults.errorElement = 'span';
defaults.ignore = ':hidden:not(.forcevalidation)';
defaults.errorPlacement = function (error, element) {
    var $fieldset = $(element).closest('.gl_form-group');
    $fieldset.find('span.gl_error-message').remove();
    $fieldset.append(error);
};
defaults.highlight = function (element) {
    var $element = $(element),
        $formGroup = $element.closest('.gl_form-group'),
        $successContainer = $formGroup.find('.gl_input-success-container');

    if (!$element.attr('formnovalidate')) {
        if ($element.val() != $element.attr('placeholder')) {
            $formGroup.find('.backend-error-message').remove();
            $formGroup.addClass('gl_has-error');
        }

        $formGroup.removeClass('gl_has-success');
        $successContainer.attr('aria-hidden', 'true');
    }
};
defaults.unhighlight = function (element) {
    var $element = $(element),
        $formGroup = $element.closest('.gl_form-group'),
        $successContainer = $formGroup.find('.gl_input-success-container');

    if (!$element.attr('formnovalidate')) {
        $formGroup.removeClass('gl_has-error');
        $formGroup.find('.backend-error-message').remove();
        $formGroup.find('span.gl_error-message').remove();

        if (!$formGroup.hasClass('gl_no-success-allowed')) {
            if ($element.val() != $element.attr('placeholder')) {
                $formGroup.addClass('gl_has-success');
                $successContainer.attr('aria-hidden', 'false');
            }
        }
    }
};

/**
 * Add XSS check validation
 */
$.validator.addMethod('xss', function (value) {
    var regexp = new RegExp(window.Resources.FORM_XSS_PATTERN, 'gi');
    return !regexp.test(value);
}, window.Resources.FORM_XSS_ERROR_MESSAGE);

/**
 * Add email validation
 */
$.validator.addMethod('email', function (value, el) {
    $.validator.messages.email = el.dataset.msgParse || '';
    var pattern = el.dataset.regexp;
    var regExp = new RegExp(pattern);

    return regExp.test(value);
}, '');

/**
 * Add pattern validation
 */
$.validator.addMethod('pattern', function (value, el) {
    $.validator.messages.pattern = el.dataset.msgParse || '';
    var pattern = el.dataset.regexp;
    var regExp = new RegExp(pattern);
    var success = regExp.test(value);

    return success;
}, '');

/**
 * Add phoneintl method
 */
$.validator.addMethod('phoneintl', function (value, el) {
    if (value === '') {
        // no value, should be managed by the required status of the field
        return true;
    }

    var $el = $(el);
    if ($el.parent().hasClass('iti')) {
        return window.intlTelInputGlobals.getInstance(el).isValidNumber();
    }

    return true;
}, window.Resources.INVALID_PHONE);

/**
 * Add file upload type check
 */
$.validator.addMethod('filetype', function (value, el) {
    if (value === '') {
        // no value, should be managed by the required status of the field
        return true;
    }

    var allowedTypes = el.accept,
        valueData = el.value.split('.'),
        fileType = valueData.length ? valueData[valueData.length - 1] : valueData[0];

    // security
    if (!allowedTypes) {
        return true;
    }

    return allowedTypes.split(',').some(function (type) {
        // on index 0 is the dot "."
        return type.indexOf(fileType) > 0;
    });
}, window.Resources.INVALID_FILE_TYPE);

var setValidatorDefaults = function () {
    $.validator.setDefaults(defaults); // security
};

module.exports.init = function () {
    setValidatorDefaults();
};
