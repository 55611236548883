'use strict';

/* trapFocusInit Helpers */
function handleLastKeydown(e) {
    if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();

        e.data.firstFocusableElement.focus();
    }
}

function handleFirstKeydown(e) {
    if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();

        e.data.lastFocusableElement.focus();
    }
}

var accessibility = {
    /**
     * @function
     * @description turn on circle focus in the defined wrapper
     * @param {DOM element} $focusableWrapper the DOM elements is the wrapper of children focusable elements : modal, open menu, etc.
     */
    trapFocusInit: function ($focusableWrapper, isFirstFocused, isReinit) {
        if ($focusableWrapper.attr('data-trap-focus') != 'on' || isReinit) {
            var $focusableElements = $focusableWrapper.find('a, button, [role="button"]').not('[tabindex="-1"]').filter(':visible'), // list for mobile CLP filter && mobile menu; can be extended
                $firstFocusableElement = $focusableElements.first(),
                $lastFocusableElement = $focusableElements.last();

            $focusableElements.filter('[data-first-focus=true]').off('keydown', null, handleFirstKeydown).removeAttr('data-first-focus');
            $focusableElements.filter('[data-last-focus=true]').off('keydown', null, handleLastKeydown).removeAttr('data-last-focus');

            // Set focus on first input
            if (isFirstFocused) {
                $firstFocusableElement.focus();
            }

            // Redirect last tab to first input
            $lastFocusableElement.attr('data-last-focus', 'true')
                .on('keydown', null, {firstFocusableElement: $firstFocusableElement}, handleLastKeydown);

            // Redirect last tab to first input
            $firstFocusableElement.attr('data-first-focus', 'true')
                .on('keydown', null, {lastFocusableElement: $lastFocusableElement}, handleFirstKeydown);

            // mark trapFocusInit attached
            $focusableWrapper.attr('data-trap-focus', 'on');
        }
    }
};

module.exports = accessibility;
