'use strict';

/* globals $ document window */

var cache = {};

/**
 * Init donation page cache
 */
var initCache = function () {
    cache.$document = $(document);
    cache.$donationWrapper = cache.$document.find('.js-donation_wrapper');
    cache.$goBack = cache.$donationWrapper.find('.js-donation_back');
    cache.$loader = cache.$donationWrapper.find('.js-donation-loader');
};

/**
 * Additional link action
 */
var linkAction = function () {
    // render page loader
    window.vbqUtils.loader(true, false, cache.$loader);
};

/**
 * Initiate the provieded donation form step
 */
var loadDonationFormStep = function () {
    // form step is stored in the pageContext.type attribute
    var formStep = window.pageContext.type;

    // do nothing if formStep is not available
    if (!formStep) {
        return;
    }

    // initiate the step
    if (formStep == 'step1') {
        require('./step1').init(cache.$donationWrapper);
    }
    else if (formStep == 'step2') {
        require('./step2').init(cache.$donationWrapper);
    }
    else if (formStep == 'step3') {
        require('./step3').init(cache.$donationWrapper);
    }

    // init floating labels on the form
    window.vbqUtils.initFloatingLabels($('.js-donation_form'));

    // attach loader on page re-loading
    cache.$goBack.on('click', linkAction);
};


module.exports.init = function () {
    initCache();
    loadDonationFormStep();
};
