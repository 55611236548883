'use strict';

/* globals window FormData $ */

var adyenscript = require('./adyenscript'),
    cache = {};

/**
 * Initialize donation form step 1 cache
 * @param {jQuery Element} $donationWrapper - donation wrapper jQuery element
 */
var initCache = function ($donationWrapper) {
    cache.$donationWrapper = $donationWrapper;
    cache.$form = cache.$donationWrapper.find('#js-donation_step3');
    cache.$submit = cache.$form.find('.js-donation_submit');
    cache.$errorWrapper = $donationWrapper.find('.js-error_message');
    cache.$donationStep3loader = cache.$donationWrapper.find('.js-donation_step3-loader');

    cache.cardfields = {
        $type: cache.$form.find('#dwfrm_donationstep3_adyenCardType'),
        $owner: cache.$form.find('#dwfrm_donationstep3_adyenCardOwner'),
        $number: cache.$form.find('#dwfrm_donationstep3_adyenEncryptedCardNumber'),
        $month: cache.$form.find('#dwfrm_donationstep3_adyenEncryptedExpiryMonth'),
        $year: cache.$form.find('#dwfrm_donationstep3_adyenEncryptedExpiryYear'),
        $securityCode: cache.$form.find('#dwfrm_donationstep3_adyenEncryptedSecurityCode')
    };
};

/**
 * Clears form hidden input fieds values
 */
var clearCardData = function () {
    cache.cardfields.$type.val('');
    cache.cardfields.$owner.val('');
    cache.cardfields.$number.val('');
    cache.cardfields.$month.val('');
    cache.cardfields.$year.val('');
    cache.cardfields.$securityCode.val('');
};

/**
 * Copy card data from Adyen to hiden input fields
 * @param {Object} card - adyen card to copy fields from
 */
var copyCardData = function (card) {
    cache.cardfields.$type.val(window.AdyenCardBrand);
    cache.cardfields.$owner.val(card.state.data.holderName);
    cache.cardfields.$number.val(card.state.data.encryptedCardNumber);
    cache.cardfields.$month.val(card.state.data.encryptedExpiryMonth);
    cache.cardfields.$year.val(card.state.data.encryptedExpiryYear);
    cache.cardfields.$securityCode.val(card.state.data.encryptedSecurityCode);
};

/**
 * Submit action for the current form
 * @param {jQuery Object} e - event object
 */
var submitAction = function (e) {
    e.preventDefault();

    // remove any previous error message
    cache.$errorWrapper.html('');
    cache.$form.removeClass('donation_form-error');

    // security; disable submit button
    cache.$submit.attr('disabled', true);

    // validate the form
    if (!window.AdyenCard.isValid) {
        // enable the submit button
        cache.$submit.attr('disabled', false);
        // show adyen validation errors
        window.AdyenCard.showValidation();
        return;
    }

    copyCardData(window.AdyenCard);

    // disabled donation-step3 form
    cache.$form.addClass('gl_form-disabled donation_form-text_loader');
    window.vbqUtils.loader(true, false, cache.$donationStep3loader);

    // submit form
    cache.$form.submit();
};

/**
 * Initialize donation form step 1 events
 */
var initEvents = function () {
    cache.$submit.on('click', submitAction);
};

module.exports.init = function ($donationWrapper) {
    initCache($donationWrapper);
    clearCardData();
    adyenscript.init($donationWrapper);
    initEvents();
};
