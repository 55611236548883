'use strict';

/**
 * VF-25 - Vilebrequin Foundation - SFCC back-end - donation form step 3 - credit card form
 * js functionality taken from vf_int_adyen_overlay -> adyenpaymentfields.isml template
 * Changes:
 * - on Adyen card creation added:
 *  - placeholdersattribute
 *  - added possibility to add custom styles ( code commented for the moment )
 *  - added onBrand function which saves the card brand in window.AdyenCardBrand
 *  - set enableStoreDetails to false
 */

/* globals window document */

var cache = {};

/**
 * Init cache
 * @param {jQuery Element} $donationWrapper - donation wrapper jQuery element
 */
var initCache = function ($donationWrapper) {
    cache.$donationWrapper = $donationWrapper;
    cache.$card = cache.$donationWrapper.find('#card');
    cache.$browserInfo = cache.$donationWrapper.find('#dwfrm_donationstep3_browserInfo');

    // get adyen data options
    var adyenJsonOptions = cache.$donationWrapper.find('[data-adyen-options]').attr('data-adyen-options') || '{}';
    /**
     * fields:
     * - originKey
     * - loadingContext
     * - environment
     * - checkoutUrl
     * - locale
     * - MASKED_CC_PREFIX
     * - translations
     * - labels
     * - placeholders
     */
    cache.adyenOptions = JSON.parse(adyenJsonOptions);
};

/**
 * Initialize Adyen credit card component
 * @param {String} originKey - adyen origin key
 * @param {String} environment - adyen mode
 * @param {String} locale - current locale
 */
var initializeCardComponent = function (originKey, environment, locale, translations) {
    window.Configuration = {
        locale: locale,
        originKey: originKey,
        environment: environment,
        translations: {}
    };
    window.Configuration.translations[locale] = translations;

    var AdyenCheckoutObject = new window.AdyenCheckout(window.Configuration);
    // Uncomment for adding custom styles for the form fields
    // var styleObject = {
    //     base: {
    //         color: '#0c315d',
    //         fontSize: '11px',
    //         fontSmoothing: 'antialiased',
    //         fontFamily: 'Arial'
    //     },
    //     error: {
    //         color: 'red'
    //     },
    //     placeholder: {
    //         color: '#d8d8d8'
    //     },
    //     validated: {
    //         color: '#1b65b1'
    //     }
    // };

    window.CardValid = false;
    window.AdyenCard = AdyenCheckoutObject.create('card', {
        type: 'card',
        hasHolderName: true,
        holderNameRequired: true,
        groupTypes: ['visa', 'mc', 'amex'],
        enableStoreDetails: false, // window.showStoreDetails, NOT WANTED
        // Uncomment for custom styles
        // styles: styleObject,
        ariaLabels: {
            lang: cache.adyenOptions.locale,
            encryptedExpiryDate: {
                label: cache.adyenOptions.labels.expiryDate
            },
            encryptedSecurityCode: {
                label: cache.adyenOptions.labels.securityCode
            }
        },
        placeholders: {
            holderName: cache.adyenOptions.placeholders.holderName,
            encryptedCardNumber: '4111 1111 1111 1111',
            encryptedExpiryDate: '03/30',
            encryptedSecurityCode: '737'
        },
        onChange: function(state) {
            // checks whether card was valid then was changed to be invalid
            window.CardValid = state.isValid;
            window.storeDetails = state.data.storePaymentMethod;
            cache.$browserInfo.val(JSON.stringify(state.data.browserInfo));
        },
        onBrand: function (state) {
            window.AdyenCardBrand = state.brand;
        },
        onFieldValid: function (data) {
            if(data.endDigits){
                // mask credit card number
                // @TODO not used
                //$('#dwfrm_billing_paymentMethods_creditCard_number').val(cache.adyenOptions.MASKED_CC_PREFIX + data.endDigits);
            }
        }
    });

    window.AdyenCard.mount(cache.$card.get(0));
};

/**
 * Load adyen checkout script
 */
var loadScript = function () {
    var scriptTag = document.createElement('script');
    scriptTag.src = cache.adyenOptions.checkoutUrl;
    scriptTag.type = 'text/javascript';

    // append script to the donation wrapper element
    cache.$donationWrapper.get(0).appendChild(scriptTag);

    // Wait for it to load
    // Working for older browsers
    if (scriptTag.readyState) {
        scriptTag.onreadystatechange = function () {
            if (scriptTag.readyState == 'loaded' ||
                scriptTag.readyState == 'complete') {
                scriptTag.onreadystatechange = null;
                // Init Component
                initializeCardComponent(cache.adyenOptions.originKey, cache.adyenOptions.environment, cache.adyenOptions.locale, cache.adyenOptions.translations);
            }
        };
    }
    // Working for new browsers
    else {
        scriptTag.onload = function () {
            // Init Component
            initializeCardComponent(cache.adyenOptions.originKey, cache.adyenOptions.environment, cache.adyenOptions.locale, cache.adyenOptions.translations);
        };
    }
};

module.exports.init = function ($donationWrapper) {
    initCache($donationWrapper);
    loadScript();
};
