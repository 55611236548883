'use strict';

var mediaQueryList = {
    // mobile
    mobilePortrait: 'only screen and (max-width: 479px)',
    mobileLandscape: 'only screen and (min-width: 480px) and (max-width: 767px)',
    mobile: 'only screen and (max-width: 767px)',
    // tablet
    mobileAndTablet: 'only screen and (max-width: 1023px)',
    tablet: 'only screen and (min-width: 768px) and (max-width: 1023px)',
    // desktop
    tabletAndDesktop: 'only screen and (min-width: 768px)',
    fromDesktop: 'only screen and (min-width: 1024px)',
    desktop: 'only screen and (min-width: 1024px) and (max-width: 1357px)',
    desktopAndDesktopLarge: 'only screen and (min-width: 1024px) and (max-width: 1919px)',
    fromDesktopLarge: 'only screen and (min-width: 1358px)',
    desktopLarge: 'only screen and (min-width: 1358px) and (max-width: 1919px)',
    desktopExtraLarge: 'only screen and (min-width: 1920px)'
};

var breakpointHelper = function (mq) {
    if (mq && typeof mq.options === 'object' && mq.options !== null) {
        if (mq.matches && typeof mq.options.enter === 'function') {
            mq.options.enter(mq);
        }
    }
};

var breakpoint = {
    /**
     * @function
     * @description check media query
     * @param {String} mediaQueryName - media query name to find value in mediaQueryList array
     */
    is: function (mediaQueryName) {
        return window.matchMedia(this.getMediaQuery(mediaQueryName)).matches;
    },
    /**
     * @function
     * @description get media query from default list
     * @param {String} mediaQueryName - media query name to find value in mediaQueryList array
     */
    getMediaQuery: function (mediaQueryName) {
        return mediaQueryList[mediaQueryName] || 'all';
    },
    /**
     * @function
     * @description set logic on target media query
     * @param {Object} settings - mandatory arg; array of objects with media query settings:
     *        {String} media - mandatory arg; media query string
     *        {Function} enter - optional arg; execute enter function if target media query = true
     *        {Function} exit - optional arg; execute exit function if target media query = false
     * @param {Boolean} isChangeOnly - optional arg; set logic on media query change only
     * Usage:
     * approach 1: set logic on page init and page resize
     *             window.vbqUtils.breakpoint.set([]);
     * approach 2: set logic on media query change only
     *             window.vbqUtils.breakpoint.set([], true);
    */
    set: function (settings, isChangeOnly) {
        isChangeOnly = isChangeOnly != undefined ? isChangeOnly : false;

        if (settings && Array.isArray(settings)) {
            for (var i = 0; i < settings.length; i++) {
                var options = settings[i];

                if (typeof options === 'object' && options !== null) {
                    if (typeof options.media === 'string') {
                        var mediaQuery = options.media,
                            mediaQueryList = window.matchMedia(mediaQuery);

                        //  attach options to mediaQueryList
                        mediaQueryList.options = options;

                        // on page load
                        if (!isChangeOnly) {
                            breakpointHelper(mediaQueryList);
                        }

                        // on media query change
                        mediaQueryList.addEventListener('change', function(e) {
                            breakpointHelper(e.currentTarget);
                        });
                    }
                }
            }
        }
    }
};

module.exports = breakpoint;
