'use strict';

/* globals $ document parseInt window */

var cache = {};

var initCache = function () {
    cache.$document = $(document);
    cache.$html = $('html, body');
    cache.$faqWrapper = cache.$document.find('.faq-wrapper');
    cache.$faqSections = cache.$faqWrapper.find('.faq-info-title, .faq-info-desc');
    cache.$faqAccToggle = cache.$faqWrapper.find('.faq-info-title');
    cache.$faqAccPanel = cache.$faqWrapper.find('.faq-info-desc');
    cache.viewOffset = parseInt($(window).height() / 3, 10);
};

/**
 * Toggle current clicked block
 * @param {jQuery Object} e - event object
 */
var toggleAccordion = function (e) {
    // mouse click or enter/space key
    if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
        // prevent the default action to stop scrolling when space is pressed
        e.preventDefault();

        var $this = $(this),
            $next = $(this).next('.faq-info-desc'),
            $currentSection = $this.add($next),
            isActive = $this.hasClass('active');

        cache.$faqAccToggle.not($currentSection).attr('aria-expanded', 'false');
        cache.$faqAccPanel.not($currentSection).attr('aria-hidden', 'true');
        cache.$faqSections.not($currentSection).removeClass('active');

        $currentSection.toggleClass('active');
        $this.attr('aria-expanded', (isActive ? 'false': 'true'));
        $next.attr('aria-hidden', (isActive ? 'true': 'false'));

        cache.$html.animate({
            scrollTop: $next.offset().top - cache.viewOffset
        }, 1000);
    }
};

/**
 * FAQ accordion ADA init
 */
 function faqAccordionADAInit() {
    cache.$faqAccToggle.attr('role', 'tab').attr('tabindex', '0').attr('aria-expanded', 'false');
    cache.$faqAccPanel.attr('aria-hidden', 'true');

    cache.$faqAccToggle.each(function (index, el) {
        var $this = $(this);

        $this.attr('aria-controls', 'faq-id-' + index);
        $this.next('.faq-info-desc')
            .attr('role', 'tabpanel')
            .attr('id', 'faq-id-' + index)
            .attr('aria-hidden', 'true');
    });
 }

/**
 * Initialize events for customer service pages
 */
var initEvents = function () {
    // faq accordion  init
    faqAccordionADAInit();
    cache.$faqAccToggle.on('click keydown', toggleAccordion);

    // Open corresponding section in case of anchored URL
    if (window.location.hash.length > 1) {
        cache.$faqWrapper.find(window.location.hash).trigger('click');
    }
};

module.exports.init = function () {
    initCache();
    initEvents();
};
