'use strict';

/* globals window FormData $ */

var cache = {},
    _debounce = require('../../lib/lodash.debounce');

/**
 * Initialize donation form step 1 cache
 * @param {jQuery Element} $donationWrapper - donation wrapper jQuery element
 */
var initCache = function ($donationWrapper) {
    cache.$donationWrapper = $donationWrapper;
    cache.$form = cache.$donationWrapper.find('#js-donation_step2');
    cache.$zipcode = cache.$form.find('.js-donation_postal');
    cache.$country = cache.$form.find('.js-donation_country');
    cache.$submit = cache.$form.find('.js-donation_submit');
    cache.$errorWrapper = $donationWrapper.find('.js-error_message');
    cache.$donationStep2loader = cache.$donationWrapper.find('.js-donation_step2-loader');

    cache.states = {
        $all: cache.$form.find('.js-donation_stateselect'),
        $US: cache.$form.find('.js-donation_stateUS'),
        $MX: cache.$form.find('.js-donation_stateMX'),
        $CA: cache.$form.find('.js-donation_stateCA'),
        $AU: cache.$form.find('.js-donation_stateAU'),
        $other: cache.$form.find('.js-donation_state')
    };

    cache.requiredZipCodeCountries = (cache.$form.attr('data-zipcode-countries') || '').split(',');

    // cache form validator
    cache.validator = cache.$form.validate();
};

/**
 * Submit action for the current form
 * @param {jQuery Object} e - event object
 */
var submitAction = function (e) {
    e.preventDefault();

    // remove any previous error message
    cache.$errorWrapper.html('');

    // security; disable submit button
    cache.$submit.attr('disabled', true);

    // validate the form
    if (!cache.$form.valid()) {
        // enable the submit button
        cache.$submit.attr('disabled', false);
        return;
    }

    var action = cache.$form.attr('action'),
        formData = new FormData(cache.$form.get(0));

    formData.append(cache.$submit.attr('name'), 'submit');

    cache.$form.addClass('gl_form-disabled');
    window.vbqUtils.loader(true, false, cache.$donationStep2loader);

    $.ajax({
        url: action,
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
    })
    .done(function (response) {
        if (!response.success || !response.redirectUrl) {
            // enable the submit button
            cache.$submit.attr('disabled', false);

            // enable donation-step1 form
            cache.$form.removeClass('gl_form-disabled');
            window.vbqUtils.loader(false, false, cache.$donationStep2loader);

            // error messages will be shown below each field which has an error
            if (response.errorData) {
                cache.validator.showErrors(response.errorData);
                return;
            }

            // global message
            var errorMessage = response.message || window.Resources.NEWSLETTER_SUBSCRIBE_ERROR;
            cache.$errorWrapper.html(errorMessage);
            return;
        }

        // redirect the page to step 3
        window.location.href = response.redirectUrl;
    })
    .fail(function () {
        // enable the submit button
        cache.$submit.attr('disabled', false);

        // enable donation-step2 form
        cache.$form.removeClass('gl_form-disabled donation_form-special_amount');
        window.vbqUtils.loader(false, false, cache.$donationStep2loader);

        // show a general error message
        cache.$errorWrapper.html(window.Resources.TECHNICAL_ERROR);
    });
};

/**
 * Update element label with a star (*)
 * @param {jQuery} $element - jquery element
 */
var setLabelMandatory = function ($element) {
    var $label = $element.siblings('label'),
        // security; remove any '*' chars if any before adding a new one
        newLabel = $label.html().replace('*', '', 'g') + '*';

    $label.html(newLabel);
};

/**
 * Remove the star (*) from element label.
 * @param {jQuery} $element - jquery element
 */
var setLabelNonMandatory = function ($element) {
    var $label = $element.siblings('label'),
        newLabel = $label.html().replace('*', '', 'g');

    $label.html(newLabel);
};

/**
 * Hide all select state elements, update also the selected state for each of them
 * @param {String} selectedStateName - name of the selected state
 */
var updateStateStatus = function updateStateStatus(selectedStateName) {
    cache.states.$all.each(function updateState(index, element) {
        var $state = $(element),
        // check if current select states element is selected
            selectedStates = selectedStateName && $state.attr('name') == selectedStateName,
            // keep the value only for the selected state if it's available
            value = selectedStates ? $state.val() : '';

        // add mandatory label for the current select states element
        // for the rest of the select elements set label non mandatory ( remove the star from label )
        if (selectedStates) {
            setLabelMandatory($state);
        }
        else {
            setLabelNonMandatory($state);
        }

        // remove required attribute
        $state.attr('required', false);
        // remove forcevalidation class ( required for jquery validator )
        $state.removeClass('forcevalidation');
        // update state value
        $state.val(value).selectmenu('refresh');
        // hide the element
        $state.closest('.gl_select').addClass('gl_hidden');
    });
};

/**
 * Show correct states element based on the current selected country
 * @param {jQuery Object} e - event object
 */
var onCountryChange = function onCountryChange(e) {
    e.preventDefault();

    var $country = $(e.currentTarget),
        selectedCountry = $country.val(),
        $selectStates = cache.states['$' + selectedCountry],
        selectedStateName = $selectStates ? $selectStates.attr('name') : '';

    // hide all select states
    updateStateStatus(selectedStateName);

    if ($selectStates) {
        // add required attribute
        $selectStates.attr('required', true);
        // add forcevalidation class ( required for jquery validator )
        $selectStates.addClass('forcevalidation');
        // show states for the selected country
        var $activeState = $selectStates.closest('.gl_select');

        $activeState.removeClass('gl_hidden');
        window.vbqUtils.refreshSelectmenuWidth($activeState.find('select'));

        // hide the other state field
        cache.states.$other.closest('.gl_form-group').addClass('gl_hidden');
    }
    else {
        // show the other state field
        cache.states.$other.closest('.gl_form-group').removeClass('gl_hidden');
    }

    // update postal code mandatory attribute
    if (cache.requiredZipCodeCountries.indexOf(selectedCountry) > -1) {
        setLabelMandatory(cache.$zipcode);
        cache.$zipcode.attr('required', true);
    }
    else {
        setLabelNonMandatory(cache.$zipcode);
        cache.$zipcode.attr('required', false);
    }

    // if the form was already submited then check again if it's valid
    if (!cache.validator.valid()) {
        cache.$form.valid();
    }
};

/**
 * Reomve error message if a state is selected, show if no states is selected
 * The validation is done only if the form was already submited and has error message
 * Else the select element will be validated during form submision.
 * @param {jQuery Object} e - event object
 */
var onStateChange = function (e) {
    e.preventDefault();

    // if the form was already submited then check again if it's valid
    if (!cache.validator.valid()) {
        cache.$form.valid();
    }
};

/**
 * Initialize donation form step 1 events
 */
var initEvents = function () {
    cache.$submit.on('click', submitAction);
    cache.$country.on('selectmenuchange', _debounce(onCountryChange, 500, {'maxWait': 2000}));
    cache.states.$all.on('selectmenuchange', _debounce(onStateChange, 500, {'maxWait': 2000}));

    cache.$country.on('change', function (e) {
        // update jquery ui selectmenu element
        $(e.currentTarget).selectmenu('refresh').trigger('selectmenuchange');
    });

    cache.states.$all.on('change', function (e) {
        // update jquery ui selectmenu element
        $(e.currentTarget).selectmenu('refresh');
    });

    // trigger country change to auto show the correct select state element if it's the case
    cache.$country.trigger('change');
};

module.exports.init = function ($donationWrapper) {
    initCache($donationWrapper);
    initEvents();
};
