'use strict';

/* globals $ document window */

var cache = {},
    _debounce = require('../lib/lodash.debounce');

/**
 * Cache application form elements
 */
var initCache = function () {
    cache.$document = $(document);
    cache.$form = cache.$document.find('#ApplicationForm');
    cache.$recaptchaButton = cache.$form.find('.js-recaptcha_button');
    cache.$validateButton = cache.$form.find('.js-validate_button');
    cache.$phone = cache.$form.find('.js-phone_field');
    cache.$country = cache.$form.find('.js-select_country');
    cache.$postalCode = cache.$form.find('.js-postal_code');
    cache.zipCodeCountries = (cache.$form.attr('data-zipcodecountries') || '').split(',');

    cache.states = {
        $all: cache.$form.find('.js-application_stateselect'),
        $US: cache.$form.find('.js-application_stateUS'),
        $MX: cache.$form.find('.js-application_stateMX'),
        $CA: cache.$form.find('.js-application_stateCA'),
        $AU: cache.$form.find('.js-application_stateAU'),
        $other: cache.$form.find('.js-application_state')
    };

    cache.intlTelPhone = null;

    // get current country code ( geolocation )
    cache.currentCountryCode = cache.$form.attr('data-countrycode') || '';

    // cache form validator
    cache.validator = cache.$form.validate();

    cache.formSubmited = false;
};

/**
 * Initialize phone intlTel plugin
 */
var initPhoneFormat = function () {
    cache.intlTelPhone = window.vbqUtils.phoneCodes.init(cache.$country, cache.$phone, cache.currentCountryCode);
};

/**
 * Update hidden phone number with the intlTel value
 * @param {jQuery Object} e - event object
 */
var onPhoneChange = function (e) {
    e.preventDefault();

    // security, do nothing if intlTel is not initialized
    if (!cache.intlTelPhone) {
        return;
    }

    // get the intlTel number only if it's valid
    var intlTelNumber = cache.intlTelPhone.isValidNumber()
        ? cache.intlTelPhone.getNumber()
        : '';

    cache.$phone.siblings('[name=dwfrm_application_phone]').val(intlTelNumber);
};

/**
 * Scrolls the winodow to respecitve error element
 */
var scrollToErrorElement = function () {
    var topOffest = 60,
        errorElement = cache.validator.errorList.length ? cache.validator.errorList[0].element : null,
        $errorParent = errorElement ? $(errorElement).closest('.gl_has-error') : null;

    // security, do nothing if there are no error elements
    if (!$errorParent || $errorParent.length == 0) {
        return;
    }

    window.scrollTo({
        top: $errorParent.offset().top - topOffest,
        behavior: 'smooth'
    });
};

/**
 * Validate form before submit action
 * @param {jQuery Object} e - event object
 */
var validateForm = function (e) {
    e.preventDefault();

    // security, prevent double submit
    if (cache.formSubmited) {
        return;
    }

    cache.formSubmited = true;

    // don't submit if form is not valid
    if (!cache.$form.valid()) {
        cache.formSubmited = false;
        scrollToErrorElement();
        return;
    }


    // form is valid so trigger click on "recaptcha" button for recaptcha validation
    cache.$recaptchaButton.click();
};

/**
 * Update element label with a star (*)
 * @param {jQuery} $element - jquery element
 */
var setLabelMandatory = function ($element) {
    var $label = $element.siblings('label'),
        // security; remove any '*' chars if any before adding a new one
        newLabel = $label.html().replace('*', '', 'g') + '*';

    $label.html(newLabel);
};

/**
 * Remove the star (*) from element label.
 * @param {jQuery} $element - jquery element
 */
var setLabelNonMandatory = function ($element) {
    var $label = $element.siblings('label'),
        newLabel = $label.html().replace('*', '', 'g');

    $label.html(newLabel);
};

/**
 * Hide all select state elements, update also the selected state for each of them
 * @param {String} selectedStateName - name of the selected state
 */
var updateStateStatus = function updateStateStatus(selectedStateName) {
    cache.states.$all.each(function updateState(index, element) {
        var $state = $(element),
        // check if current select states element is selected
            selectedStates = selectedStateName && $state.attr('name') == selectedStateName,
            // keep the value only for the selected state if it's available
            value = selectedStates ? $state.val() : '';

        // add mandatory label for the current select states element
        // for the rest of the select elements set label non mandatory ( remove the star from label )
        if (selectedStates) {
            setLabelMandatory($state);
        }
        else {
            setLabelNonMandatory($state);
        }

        // remove required attribute
        $state.attr('required', false);
        // remove forcevalidation class ( required for jquery validator )
        $state.removeClass('forcevalidation');
        // update state value
        $state.val(value).selectmenu('refresh');
        // hide the element
        $state.closest('.gl_select').addClass('gl_hidden');
    });
};

/**
 * Update postal code mandatory status based on the current country code
 * @param {String} countryCode - current selected country
 */
function updatePostalCode(countryCode) {
    // do nothing if no country is selected
    if (!countryCode) {
        return;
    }

    var isFieldRequired = cache.zipCodeCountries.indexOf(countryCode) != -1;

    if (isFieldRequired) {
        setLabelMandatory(cache.$postalCode);
    }
    else {
        setLabelNonMandatory(cache.$postalCode);
    }

    cache.$postalCode.prop('required', isFieldRequired);
}

/**
 * Show correct states element based on the current selected country
 * @param {jQuery Object} e - event object
 */
var onCountryChange = function onCountryChange(e) {
    e.preventDefault();

    var $country = $(e.currentTarget),
        selectedCountry = $country.val(),
        $selectStates = cache.states['$' + selectedCountry],
        selectedStateName = $selectStates ? $selectStates.attr('name') : '';

    // hide all select states
    updateStateStatus(selectedStateName);

    // update postal code mandatory status
    updatePostalCode(selectedCountry);

    if ($selectStates) {
        // add required attribute
        $selectStates.attr('required', true);
        // add forcevalidation class ( required for jquery validator )
        $selectStates.addClass('forcevalidation');
        // show states for the selected country
        var $activeState = $selectStates.closest('.gl_select');

        $activeState.removeClass('gl_hidden');
        window.vbqUtils.refreshSelectmenuWidth($activeState.find('select'));

        // hide the other state field
        cache.states.$other.closest('.gl_form-group').addClass('gl_hidden');
    }
    else {
        // show the other state field
        cache.states.$other.closest('.gl_form-group').removeClass('gl_hidden');
    }

    // if the form was already submited then check again if it's valid
    if (!cache.validator.valid()) {
        cache.$form.valid();
    }
};

/**
 * Reomve error message if a state is selected, show if no states is selected
 * The validation is done only if the form was already submited and has error message
 * Else the select element will be validated during form submision.
 * @param {jQuery Object} e - event object
 */
var onStateChange = function (e) {
    e.preventDefault();

    // if the form was already submited then check again if it's valid
    if (!cache.validator.valid()) {
        cache.$form.valid();
    }
};

/**
 * Initialize events
 */
var initEvents = function () {
    cache.$validateButton.on('click', validateForm);
    cache.$phone.on('change intltelutilsloaded', onPhoneChange);
    cache.$country.on('selectmenuchange', _debounce(onCountryChange, 500, {'maxWait': 2000}));
    cache.states.$all.on('selectmenuchange', _debounce(onStateChange, 500, {'maxWait': 2000}));

    cache.$country.on('change', function (e) {
        // update jquery ui selectmenu element
        $(e.currentTarget).selectmenu('refresh').trigger('selectmenuchange');
    });

    cache.states.$all.on('change', function (e) {
        // update jquery ui selectmenu element
        $(e.currentTarget).selectmenu('refresh');
    });

    // trigger country change to auto show the correct select state element if it's the case
    cache.$country.trigger('change');
};

module.exports.init = function () {
    initCache();
    initPhoneFormat();
    initEvents();
};
