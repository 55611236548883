'use strict';

/* globals $ document window FormData */

var cache = {},
    submitInProcess = false;

/**
 * Cache newsletter elements
 */
var initCache = function () {
    cache.$document = $(document);
    cache.newsletter = {};
    cache.newsletter.$formWrapper = cache.$document.find('.js-newsletter-form');
    cache.newsletter.$form = cache.newsletter.$formWrapper.find('#newsletter-subscribe');
    cache.newsletter.$email = cache.newsletter.$form.find('.js-newsletter-email');
    cache.newsletter.$consent = cache.newsletter.$form.find('input.consent');
    cache.newsletter.$subscribe = cache.newsletter.$form.find('button');
    cache.newsletter.$successMessageWrapper = cache.newsletter.$formWrapper.find('.success_message-wrapper');
    cache.newsletter.$errorMessageWrapper = cache.newsletter.$form.find('.error_message-wrapper');
    cache.newsletter.$loader = cache.newsletter.$formWrapper.find('.js-newsletter-loader');
};

/**
 * Submit newsletter form
 * @param {Jquery event} e
 */
var submitForm = function (e) {
    e.preventDefault();

    if (submitInProcess) {
        return;
    }
    // block a new submit action
    submitInProcess = true;

    // clear any response message
    cache.newsletter.$successMessageWrapper.html('');
    cache.newsletter.$errorMessageWrapper.html('');

    var validator = cache.newsletter.$form.validate();

    if (!cache.newsletter.$form.valid()) {
        // allow a new submit action
        submitInProcess = false;
        return;
    }

    var formData = new FormData();
    formData.append(cache.newsletter.$email.attr('name'), cache.newsletter.$email.val());
    formData.append(cache.newsletter.$consent.attr('name'), cache.newsletter.$consent.prop('checked') ? 'accepted' : '');
    formData.append(cache.newsletter.$subscribe.attr('name'), 'subscribe');

    // disabled newsletter form
    cache.newsletter.$form.addClass('gl_form-disabled');
    window.vbqUtils.loader(true, false, cache.newsletter.$loader);

    $.ajax({
        url: cache.newsletter.$form.attr('action'),
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
    })
    .done(function (response) {
        // allow a new submit action
        submitInProcess = false;

        if (!response.success) {
            // error messages will be shown below each field which has an error
            if (response.errorData) {
                validator.showErrors(response.errorData);
                return;
            }

            // global message
            var errorMessage = response.message || window.Resources.NEWSLETTER_SUBSCRIBE_ERROR;
            cache.newsletter.$errorMessageWrapper.html(errorMessage);

            // enable newsletter form
            cache.newsletter.$form.removeClass('gl_form-disabled');
            window.vbqUtils.loader(false, false, cache.newsletter.$loader);

            return;
        }

        // remove form validation; it will be enabled next time the user will click on submit button
        validator.destroy();

        // activate success form state
        cache.newsletter.$form.remove();
        cache.newsletter.$successMessageWrapper.removeClass('gl_hidden').html(response.message);

        window.vbqUtils.loader(false, false, cache.newsletter.$loader);
    })
    .fail(function () {
        // allow a new submit action
        submitInProcess = false;

        // enable newsletter form
        cache.newsletter.$form.removeClass('gl_form-disabled');
        window.vbqUtils.loader(false, false, cache.newsletter.$loader);

        // general error
        cache.newsletter.$errorMessageWrapper.html(window.Resources.NEWSLETTER_SUBSCRIBE_ERROR);
    });
};

/**
 * Bind events for newsletter
 */
var initEvents = function () {
    cache.newsletter.$subscribe.on('click', submitForm);
};

module.exports.init = function () {
    initCache();
    initEvents();
};
