'use strict';

/* globals $ document */

var cache = {};

/**
 * Init cache
 */
var initCache = function () {
    cache.$document = $(document);
    cache.$filterButton = cache.$document.find('.js-news_filter');
    cache.$highlightedArticle = cache.$document.find('.js-highlighed_article');
    cache.$articlesList = cache.$document.find('.js-articles_list');
    cache.$showMore = cache.$articlesList.find('.js-showMore_articles');
    cache.$loader = cache.$document.find('.js-articles-loader');
    cache.$listLoader = cache.$document.find('.js-articles-list-loader');
};

/**
 * Get filtered articles
 * @param {jQuery object} event - event object
 */
var getFilteredArticles = function (event) {
    event.preventDefault();

    var $this = $(event.currentTarget);

    // clear previous filters
    cache.$filterButton.not($this).removeClass('selected');

    // toggle class 'selected'
    $this.toggleClass('selected');

    var isFilterSelected = $this.hasClass('selected'),
        url = $this.attr('href'),
        filterId = isFilterSelected ? $this.attr('data-filterid') : null;

    // turn on loader animation
    window.vbqUtils.loader(true, false, cache.$loader);

    $.ajax({
        url: url,
        data: {
            filterId: filterId
        }
    })
    .then(function (response) {
        // show/hide highlighted article based if the filter is selected or unselected
        if (isFilterSelected) {
            cache.$highlightedArticle.addClass('hidden');
        }
        else {
            cache.$highlightedArticle.removeClass('hidden');
        }

        // update articles list
        cache.$articlesList.html(response);

        // update cache for show more button if exists
        cache.$showMore = cache.$articlesList.find('.js-showMore_articles');

        // turn off loader animation
        window.vbqUtils.loader(false, false, cache.$loader);
    });
};

/**
 * Get more articles and append to the existing list
 * @param {jQuery object} event - event object
 */
var showMoreArticles = function (event) {
    event.preventDefault();

    var $this = $(event.currentTarget);

    // turn on loader animation
    window.vbqUtils.loader(true, false, cache.$listLoader);

    $.ajax({
        url: $this.attr('href')
    })
    .then(function (response) {
        // remove current show more button
        if (cache.$showMore && cache.$showMore.length) {
            cache.$showMore.remove();
        }

        // append the new articles to the list
        cache.$articlesList.append(response);

        // update cache for show more button if exists
        cache.$showMore = cache.$articlesList.find('.js-showMore_articles');

        // turn off loader animation
        window.vbqUtils.loader(false, false, cache.$listLoader);
    });
};

/**
 * Init events
 */
var initEvents = function () {
    cache.$filterButton.on('click', getFilteredArticles);
    cache.$articlesList.on('click', '.js-showMore_articles', showMoreArticles);
};

module.exports.init = function () {
    initCache();
    initEvents();
};
