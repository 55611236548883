/**
 *      (c) 2009-2014 Demandware Inc.
 *      Subject to standard usage terms and conditions
 *      For all details and documentation:
 *      https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

/* globals $ window clearTimeout jQuery document */

var accessibility = require('./accessibility'),
    // ECM22-20 - Remove jRespond and directly use window.matchMedia()
    breakpoint = require('./breakpoint.js'),
    validator = require('./validator'),
    newsletter = require('./newsletter'),
    carousel = require('./carousel'),
    phoneCodes = require('./phone-codes'),
    cache = {
        $window: $(window)
    };

function navigationInit() {
    // VF-37 - Vilebrequin Foundation - SFCC front-end - navigation menu: desktop nav
    var debounceDropdownShow,
        $dropdownList = $('.js-menuList'),
        menuPanelIsVisible = false,
        menuSwitchTimeout = null;

    $('.js-menuTrigger')
        .on('mouseenter', function () {
            var $currentElement = $(this);

            clearTimeout(debounceDropdownShow);
            $dropdownList.removeClass('visible').attr('aria-expanded', 'false').attr('aria-hidden', 'true');

            if (!menuPanelIsVisible) {
                debounceDropdownShow = window.setTimeout(function () {
                    if ($currentElement.is(':hover')) {
                        $currentElement.find('.js-menuList').addClass('visible').attr('aria-expanded', 'true').attr('aria-hidden', 'false');
                        menuPanelIsVisible = true;

                        if (menuSwitchTimeout) { // security, should not happen with the configured delays (500 / 100)
                            window.clearTimeout(menuSwitchTimeout);
                        }
                    }
                }, 500);
            }
            else {
                $currentElement.find('.js-menuList').addClass('visible').attr('aria-expanded', 'true').attr('aria-hidden', 'false');
                menuPanelIsVisible = true;
                if (menuSwitchTimeout) {
                    window.clearTimeout(menuSwitchTimeout);
                }
            }
        })
        .on('mouseleave', function () {
            clearTimeout(debounceDropdownShow);
            $dropdownList.removeClass('visible').attr('aria-expanded', 'false').attr('aria-hidden', 'true');
            menuSwitchTimeout = window.setTimeout(function () {
                menuPanelIsVisible = false;
            }, 100);
        });

    $('.js-menuTrigger').on('keydown', function (e) {
        // keyboard control using enter/space key
        if (e.keyCode == 13 || e.keyCode == 32) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $currentMenuList = $(this).find('.js-menuList'),
                isMenuVisible = $currentMenuList.hasClass('visible');

            $dropdownList.not($currentMenuList).removeClass('visible').attr('aria-expanded', 'false').attr('aria-hidden', 'true');
            $currentMenuList.toggleClass('visible', !isMenuVisible).attr('aria-expanded', isMenuVisible ? 'false' : 'true').attr('aria-hidden', isMenuVisible ? 'true' : 'false');
        }
    });

    // VF-37 - Vilebrequin Foundation - SFCC front-end - navigation menu: mobile nav
    var $mobileMenu = $('.js-mobileMenu'),
        $mobileMenuOpen = $('.js-menu-open'),
        $mobileMenuClose = $('.js-mobileMenuClose');

    // VF-37 - Vilebrequin Foundation - SFCC front-end - navigation menu: mobile nav accordion
    $mobileMenu.customAccordion();

    $mobileMenuOpen.on('click keydown', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            $mobileMenu.addClass('show');

            // focus on close menu button on menu open
            $mobileMenuClose.focus();
        }
    });

    $mobileMenuClose.on('click tap', function () {
        $mobileMenu.removeClass('show');

        // focus on open menu button on menu close
        $mobileMenuOpen.focus();
    });

    $mobileMenu.on('click tap', function (e) {
        // close mobile menu on overlay only
        if (e.target != e.currentTarget) {
            return;
        }

        $mobileMenuClose.click();
    });

    // turn on circle focus in the defined wrapper
    accessibility.trapFocusInit($mobileMenu);
}

// EW-46 Revised DESKTOP forms
function initFloatingLabels ($forms) {
    var updateStatus = function () {
        var $this = $(this);
        $this.closest('.gl_input-wrapper').toggleClass('filled', $this.val() !== '');
    };

    $forms.each(function () {
        var $form = $(this);
        $form.find('.gl_floating-label').siblings('.gl-input').off('input blur change', updateStatus)
            // focus case is managed by CSS
            .on('input blur change', updateStatus) // input and change events are needed to cover autofill cases (chrome, lastpass, ...)
            .each(updateStatus);
    });
}

// VF-46 -Vilebrequin Foundation - SFCC front-end - program application form
function initUploadFileInput() {
    var $fileInputs = $('.gl-input_file');

    $fileInputs.each(function () {
        var $fileInput = $(this),
            $fileInputWrapper = $fileInput.parent('.gl-input_file-fake'),
            $fileVisibleValue = $fileInputWrapper.find('.gl-input_file-value');

        if ($fileInput.val().length == 0) {
            $fileVisibleValue.text($fileInput.attr('customplaceholder'));
        }

        $fileInputs.on('change', function () {
            var $this = $(this);

            $fileVisibleValue.text($this.val().length == 0 ? $this.attr('customplaceholder') : $this[0].files[0].name);
        });

        $fileInputs.on('focus', function () {
            $(this).parent('.gl-input_file-fake').addClass('focus');
        });

        $fileInputs.on('blur', function () {
            $(this).parent('.gl-input_file-fake').removeClass('focus');
        });
    });
}

// VF-130 - Donation form - country field keyboard navigation
/*  Set selectmenu placeholder using widget factory
    https://github.com/jquery/jquery-ui/releases (1.12.1)
    folder: jquery-ui-1.12.1/ui/widgets/selectmenu.js
*/
$.widget('app.selectmenu', $.ui.selectmenu, {
    // jquery-ui.min.js v1.12.1 - overwriting _renderButtonItem
    /* origin jquery-ui plugin _renderButtonItem function
    _renderButtonItem: function( item ) {
        var buttonItem = $( "<span>" );

        this._setText( buttonItem, item.label );
        this._addClass( buttonItem, "ui-selectmenu-text" );

        return buttonItem;
    }
    */
    _renderButtonItem: function(item) {
        var buttonItem = $('<span>'),
            placeholder = this.element.attr('data-placeholder');

        // set selectmenu placeholder if default index = 0 on initialization
        if (item.index == 0 && placeholder) {
            this._setText(buttonItem, placeholder);
        } else {
            this._setText(buttonItem, item.label);
        }
        this._addClass(buttonItem, 'ui-selectmenu-text');

        return buttonItem;
    }
});

// EADA23-7 - ADA #285 - Button marked as a tab on the e gift card accordion
$.fn.customAccordion = function (method, methodData) {
    var _$this = this,
        // For nested accordions separation, remove accordion elements that are children of the main accordion.
        $tabs = _$this.find('.js-accordion-tab').filter(function () {
            return !$(this).parentsUntil(_$this, '.js-accordion-tab').length;
        }),
        $tabsToggle = _$this.find('.js-tabs-accordion-header').attr('aria-expanded', 'false').attr('tabindex' , '0'),
        $tabsContent = $tabsToggle.next(),
        methods = {
            init: function () {
                $tabs.removeAttr('style').attr('role' ,'region').attr('aria-hidden', 'true');

                $tabsToggle.each(function (index, el) {
                    var $accordionToggle = $(el),
                        $accordionContent = $accordionToggle.next(),
                        accordionToggleRole = $accordionToggle.attr('role') || '';

                    // EADA23-16 - ADA #265 - add role heading and aria-level 2 on accordion headers on the PDP
                    $accordionToggle.attr('role', accordionToggleRole.length ? ('button ' + accordionToggleRole) : 'button');

                    // ID generation
                    if ($accordionToggle.attr('id') == undefined) {
                        $accordionToggle.uniqueId();
                    }
                    if ($accordionContent.attr('id') == undefined) {
                        $accordionContent.uniqueId();
                    }
                    $accordionToggle.attr('aria-controls', $accordionContent.attr('id'));
                    $accordionContent.attr('aria-labelledby', $accordionToggle.attr('id'));

                    if ($accordionContent.is(':visible')) {
                        $accordionToggle.addClass('active').attr('aria-expanded', 'true').next().addClass('active').attr('aria-hidden', 'false');
                    }
                });

                _$this.on('click keydown', '.js-tabs-accordion-header', function (e) {
                    // mouse click or enter/space key
                    if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                        // prevent the default action to stop scrolling when space is pressed
                        e.preventDefault();

                        var $this = $(this);

                        if ($this.hasClass('active')) {
                            $this.removeClass('active').attr('aria-expanded', 'false').next().removeClass('active').attr('aria-hidden', 'true').slideUp();
                            window.vbqUtils.cache.$window.trigger('accordion.tabInactive');
                        }
                        else {
                            var $target = $(e.target).closest('.js-tabs-accordion-header');
                            $tabsToggle.not($target).removeClass('active').attr('aria-expanded', 'false').next().removeClass('active').attr('aria-hidden', 'true').slideUp();
                            $this.addClass('active').attr('aria-expanded', 'true').next().addClass('active').attr('aria-hidden', 'false').slideDown();
                            window.vbqUtils.cache.$window.trigger('accordion.tabActive', [{activeTarget: $target}]);
                        }
                    }
                });
                _$this.addClass('accordion-initialized').data('customAccordion', true);
            },
            active: function (index) {
                if (index !== undefined && !$tabs.eq(index).hasClass('active')) {
                    $tabs.eq(index).prev().trigger('click');
                }
            },
            inactive: function (index) {
                if (index !== undefined && $tabs.eq(index).hasClass('active')) {
                    $tabs.eq(index).prev().trigger('click');
                }
            },
            destroy: function () {
                _$this.removeClass('accordion-initialized').data('customAccordion', false);
                $tabsToggle.removeClass('active').removeAttr('role aria-controls aria-expanded');
                $tabsContent.removeClass('active').removeAttr('role aria-hidden aria-labelledby style');
                _$this.off('click keydown', '.js-tabs-accordion-header');
            }
        };

    if (methods[method]) {
        if (!_$this.data('customAccordion')) {
            $.error('Cannot call ' +  method + ' prior to plugin initialisation');
        } else {
            methods[method](methodData);
        }
    } else if (!_$this.data('customAccordion')) {
        methods.init();
    }

    return this;
};

/**
 * @private
 * @function
 * @description Refresh selectmenu width: widget & menuWidget
 */
function refreshSelectmenuWidth($selects) {
    $selects.each(function () {
        var $select = $(this);

        // hide select button to get origin select width
        // important for the next correct calculation
        $select.selectmenu('widget').hide();

        var selectWidth = $select.outerWidth(),
            maxWidth = selectWidth - 2; // select width - 2px for border

        // restrict button element to apply ellipsis text-overflow via css
        $select.selectmenu('widget').css('maxWidth', selectWidth);
        $select.selectmenu('widget').show();

        // restrict menu widget to align with select width
        $select.selectmenu('menuWidget').css('maxWidth', maxWidth);
    });
}

/**
 * @private
 * @function
 * @description Initializes jQuery UI plugins
 */
function initializeJQueryUiPlugins() {
    var $customStyleInputs = $('.gl_radio:not(.gl_radio-buttons), .gl_checkbox:not(.gl_checkbox-buttons)'),
        $customStyleSelects = $('.gl_select-custom select');

    $customStyleInputs
        .filter('input[type=radio], input[type=checkbox]')
        .add($customStyleInputs.find('input[type=radio], input[type=checkbox]'))
        .checkboxradio();

    $customStyleSelects.selectmenu();
    refreshSelectmenuWidth($customStyleSelects);

    cache.$window.resize(function() {
        $customStyleSelects.selectmenu('close');
        refreshSelectmenuWidth($customStyleSelects);
    });
}

/**
 * @function
 * @description show progress loader
 * @param {Boolean} state - false to hide loader, undefined or true to show it
 * @param {Boolean} alert - false to not show alert, undefined or true to show it
 */
function loader(state, alert, $customLoader) {
    var $loader = $customLoader !== undefined ? $customLoader : $('.js-loader'),
        $alert = $loader.next('.js-progress-alert');

    if (state === undefined || state) {
        if (alert === undefined || alert) {
            $alert.html('<span>' + window.Resources.ALERT_MESSAGE + '</span>');
        }
        $loader.show();
    }
    else {
        if (!alert) {
            $alert.html('');
        }
        $loader.hide();
    }
}

function initializeDom() {
    // Initialize the jQuery UI elements
    initializeJQueryUiPlugins();

    // Init any found carousel on the page
    carousel.init();
}

function initializeEvents() {
    // VF-37 - Vilebrequin Foundation - SFCC front-end - navigation menu
    navigationInit();

    initFloatingLabels($('form'));

    // VF-46 -Vilebrequin Foundation - SFCC front-end - program application form
    initUploadFileInput();
}

var pages = {
    news: require('./pages/news'),
    contactus: require('./pages/contactus'),
    content: require('./pages/content/customerservice'),
    donation: require('./pages/donation/'),
    application: require('./pages/application')
};

var app = {
    init: function () {
        window.vbqUtils = window.vbqUtils || {};
        window.vbqUtils = $.extend({}, window.vbqUtils, {
            cache: cache,
            breakpoint: breakpoint,
            loader: loader,
            initFloatingLabels: initFloatingLabels,
            initializeJQueryUiPlugins: initializeJQueryUiPlugins,
            refreshSelectmenuWidth: refreshSelectmenuWidth,
            phoneCodes: phoneCodes
        });

        initializeDom();
        initializeEvents();
        validator.init();
        newsletter.init();

        // execute page specific initializations
        var ns = window.pageContext.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
    }
};

// initialize app
jQuery(document).ready(function ($) { // pass $ as parameter to scope it as GTM overrides it (rakuten)
    app.init();
});
