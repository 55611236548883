'use strict';

/* globals window $ */

var _debounce = require('./lib/lodash.debounce');

var CarouselHelper = function ($carousel) {
    /**
     * Get carousel settings
     *
     * @param {String} type - carousel type (large-desktop/desktop/tablet/mobile)
     * @return {Object} carousel settings
     */
    var getCarouselSettings = function(type) {
        var settings = {},
            enable = true,
            showDots = false,
            showArrows = false,
            halfSlideMode = false,
            infinite = true,
            variableWidth = false,
            slidesToShow,
            isFallBackMode,
            rows,
            slidesPerRow,
            appendArrows,
            appendDots;

        switch (type) {
            case 'desktop-extra-large':
                settings = JSON.parse($carousel.attr('data-desktop-extra-large-settings') || '{}');
                isFallBackMode = !(settings && settings.hasOwnProperty('enable'));
                enable = settings && settings.hasOwnProperty('enable') ? settings.enable : enable;
                variableWidth = settings && settings.hasOwnProperty('variableWidth') ? settings.variableWidth : variableWidth;
                slidesToShow = settings && settings.slidesToShow || 6;
                showDots = settings ? settings.showDots : showDots;
                showArrows = settings ? settings.showArrows : showArrows;
                halfSlideMode = settings ? settings.halfSlideMode : halfSlideMode;
                infinite = settings && settings.hasOwnProperty('infinite') ? settings.infinite : infinite;
                rows = settings && settings.hasOwnProperty('rows') ? settings.rows : '';
                slidesPerRow = settings && settings.hasOwnProperty('slidesPerRow') ? settings.slidesPerRow : '';
                appendArrows = settings && settings.hasOwnProperty('appendArrows') ? settings.appendArrows : '';
                appendDots = settings && settings.hasOwnProperty('appendDots') ? settings.appendDots : '';
                break;

            case 'desktop-large':
                settings = JSON.parse($carousel.attr('data-desktop-large-settings') || '{}');
                isFallBackMode = !(settings && settings.hasOwnProperty('enable'));
                enable = settings && settings.hasOwnProperty('enable') ? settings.enable : enable;
                variableWidth = settings && settings.hasOwnProperty('variableWidth') ? settings.variableWidth : variableWidth;
                slidesToShow = settings && settings.slidesToShow || 5;
                showDots = settings ? settings.showDots : showDots;
                showArrows = settings ? settings.showArrows : showArrows;
                halfSlideMode = settings ? settings.halfSlideMode : halfSlideMode;
                infinite = settings && settings.hasOwnProperty('infinite') ? settings.infinite : infinite;
                rows = settings && settings.hasOwnProperty('rows') ? settings.rows : '';
                slidesPerRow = settings && settings.hasOwnProperty('slidesPerRow') ? settings.slidesPerRow : '';
                appendArrows = settings && settings.hasOwnProperty('appendArrows') ? settings.appendArrows : '';
                appendDots = settings && settings.hasOwnProperty('appendDots') ? settings.appendDots : '';
                break;

            case 'desktop':
                settings = JSON.parse($carousel.attr('data-desktop-settings') || '{}');
                isFallBackMode = !(settings && settings.hasOwnProperty('enable'));
                enable = settings && settings.hasOwnProperty('enable') ? settings.enable : enable;
                variableWidth = settings && settings.hasOwnProperty('variableWidth') ? settings.variableWidth : variableWidth;
                slidesToShow = settings && settings.slidesToShow || 4;
                showDots = settings ? settings.showDots : showDots;
                showArrows = settings ? settings.showArrows : showArrows;
                halfSlideMode = settings ? settings.halfSlideMode : halfSlideMode;
                infinite = settings && settings.hasOwnProperty('infinite') ? settings.infinite : infinite;
                rows = settings && settings.hasOwnProperty('rows') ? settings.rows : '';
                slidesPerRow = settings && settings.hasOwnProperty('slidesPerRow') ? settings.slidesPerRow : '';
                appendArrows = settings && settings.hasOwnProperty('appendArrows') ? settings.appendArrows : '';
                appendDots = settings && settings.hasOwnProperty('appendDots') ? settings.appendDots : '';
                break;

            case 'tablet':
                settings = JSON.parse($carousel.attr('data-tablet-settings') || '{}');
                isFallBackMode = !(settings && settings.hasOwnProperty('enable'));
                enable = settings ? settings.enable : false;
                variableWidth = settings && settings.hasOwnProperty('variableWidth') ? settings.variableWidth : variableWidth;
                slidesToShow = settings && settings.slidesToShow || 3;
                showDots = settings ? settings.showDots : showDots;
                showArrows = settings ? settings.showArrows : showArrows;
                halfSlideMode = settings ? settings.halfSlideMode : halfSlideMode;
                infinite = settings && settings.hasOwnProperty('infinite') ? settings.infinite : infinite;
                rows = settings && settings.hasOwnProperty('rows') ? settings.rows : '';
                slidesPerRow = settings && settings.hasOwnProperty('slidesPerRow') ? settings.slidesPerRow : '';
                appendArrows = settings && settings.hasOwnProperty('appendArrows') ? settings.appendArrows : '';
                appendDots = settings && settings.hasOwnProperty('appendDots') ? settings.appendDots : '';
                break;

            case 'mobile':
                settings = JSON.parse($carousel.attr('data-mobile-settings') || '{}');
                isFallBackMode = !(settings && settings.hasOwnProperty('enable'));
                enable = settings ? settings.enable : false;
                variableWidth = settings && settings.hasOwnProperty('variableWidth') ? settings.variableWidth : variableWidth;
                slidesToShow = settings && settings.slidesToShow || 1;
                showDots = settings ? settings.showDots : true;
                showArrows = settings ? settings.showArrows : showArrows;
                halfSlideMode = settings ? settings.halfSlideMode : halfSlideMode;
                infinite = settings && settings.hasOwnProperty('infinite') ? settings.infinite : infinite;
                rows = settings && settings.hasOwnProperty('rows') ? settings.rows : '';
                slidesPerRow = settings && settings.hasOwnProperty('slidesPerRow') ? settings.slidesPerRow : '';
                appendArrows = settings && settings.hasOwnProperty('appendArrows') ? settings.appendArrows : '';
                appendDots = settings && settings.hasOwnProperty('appendDots') ? settings.appendDots : '';
                break;

            default:
                // do nothing
        }

        return {
            isFallBackMode: isFallBackMode,
            enable: enable,
            slidesToShow: slidesToShow,
            showDots: showDots,
            showArrows: showArrows,
            halfSlideMode: halfSlideMode,
            infinite: infinite,
            variableWidth: variableWidth,
            rows: rows,
            slidesPerRow: slidesPerRow,
            appendArrows: appendArrows,
            appendDots: appendDots
        };
    };

    /**
     * Initialize carousel
     *
     * @param {Object} settings - carousel settings
     */
    var initCarousel = function (settings) {
        disableCarousel(); // security

        if (settings.enable && !$carousel.hasClass('slick-initialized') && $carousel.children().length > settings.slidesToShow) {
            var carouselSettings = {
                slidesToShow: settings.slidesToShow > 0 ? settings.slidesToShow : 1, // minimum 1 slide
                dots: settings.showDots,
                arrows: settings.showArrows,
                infinite: settings.infinite,
                variableWidth: settings.variableWidth
            };

            if (settings.rows) {
                carouselSettings.rows = settings.rows;
            }

            if (settings.slidesPerRow) {
                carouselSettings.slidesPerRow = settings.slidesPerRow;
            }

            if (settings.appendArrows) {
                carouselSettings.appendArrows = settings.appendArrows;
            }

            if (settings.appendDots) {
                carouselSettings.appendDots = settings.appendDots;
            }

            if (settings.halfSlideMode) {
                // halfSlideModeSlidesToShow is initial slidesToShow 
                var halfSlideModeSlidesToShow = 2;

                // force infinite to render halfSlideModee carousel correctly
                carouselSettings.infinite = true;

                // minimum slidesToShow should be 2 to render halfSlideMode correctly
                if (settings.slidesToShow > 1) {
                    halfSlideModeSlidesToShow = settings.slidesToShow;
                }

                // half-visible slides on both side leads to -1 slides in reachable slides area
                // carouselSettings.slidesToShow is adjusted slidesToShow
                carouselSettings.slidesToShow = halfSlideModeSlidesToShow - 1;

                // halfSlideMode carousel:
                // case 1: adjusted slidesToShow is odd
                //         infinite = true;
                //         centerPadding = space of 1 half-slide on both side
                //         centerMode = true;
                // case 2: adjusted slidesToShow is even
                //         infinite = true;
                //         centerPadding = space of 1 half-slide on both side
                //         no centerMode

                // centerMode enables centered view with odd numbered slidesToShow counts (case 1)
                if (carouselSettings.slidesToShow % 2 === 1) {
                    carouselSettings.centerMode = true;
                } 

                // equal space of 2 half-slides on both side
                carouselSettings.centerPadding = (100 / halfSlideModeSlidesToShow / 2) + '%';
            }

            $carousel.slick(carouselSettings);

            if (settings.showArrows && !settings.halfSlideMode) {
                $carousel.addClass('carousel-with-arrows');
            }

            if (settings.halfSlideMode) {
                $carousel.addClass('half-carousel-mode');
            }
        }
    };

    /**
     * Disable carousel
     */
    var disableCarousel = function () {
        if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('unslick').removeClass('carousel-with-arrows half-carousel-mode');
        }
    };

    this.$carousel = $carousel;
    this.getCarouselSettings = getCarouselSettings;
    this.initCarousel = initCarousel;
    this.disableCarousel = disableCarousel;
};

var carousel = {
    /**
     * Create carousel based on found settings
     *
     * @param {jQuery} $carousel
     */
    carouselInit: function ($carousel) {
        var carouselHelper = new CarouselHelper($carousel),
            desktopExtraLargeSettings = carouselHelper.getCarouselSettings('desktop-extra-large'),
            desktopLargeSettings = carouselHelper.getCarouselSettings('desktop-large'),
            desktopSettings = carouselHelper.getCarouselSettings('desktop'),
            tabletSettings = carouselHelper.getCarouselSettings('tablet'),
            mobileSettings = carouselHelper.getCarouselSettings('mobile');

        if (carouselHelper.$carousel.hasClass('slick-initialized')) {
            // don't execute any of the remaining calls as the carousel is already initialized
            // this can be the case when .carousel.init() is called multiple times
            return;
        }

        // if extra desktop-extra-large settings is not set then use desktop-large settings values
        if (desktopExtraLargeSettings.isFallBackMode) {
            // make a copy of the settings
            desktopExtraLargeSettings = JSON.parse(JSON.stringify(desktopLargeSettings));
        }

        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobile'),
                enter: function () {
                    // if carousel is enabled then initialize it
                    if (mobileSettings.enable) {
                        carouselHelper.initCarousel(mobileSettings);
                    }
                    else {
                        carouselHelper.disableCarousel();
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('tablet'),
                enter: function () {
                    // if carousel is enabled then initialize it
                    if (tabletSettings.enable) {
                        carouselHelper.initCarousel(tabletSettings);
                    }
                    else {
                        carouselHelper.disableCarousel();
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktop'),
                enter: function () {
                    // if carousel is enabled then initialize it
                    if (desktopSettings.enable) {
                        carouselHelper.initCarousel(desktopSettings);
                    }
                    else {
                        carouselHelper.disableCarousel();
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktopLarge'),
                enter: function () {
                    // if carousel is enabled then initialize it
                    if (desktopLargeSettings.enable) {
                        carouselHelper.initCarousel(desktopLargeSettings);
                    }
                    else {
                        carouselHelper.disableCarousel();
                    }
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('desktopExtraLarge'),
                enter: function () {
                    // if carousel is enabled then initialize it
                    if (desktopExtraLargeSettings.enable) {
                        carouselHelper.initCarousel(desktopExtraLargeSettings);
                    }
                    else {
                        carouselHelper.disableCarousel();
                    }
                }
            }
        ]);

        carouselHelper.$carousel.find('.lazyload').each(function (index, el) {
            window.lazySizes.loader.unveil(el);
        });

        window.vbqUtils.cache.$window.resize(_debounce(function () {
            if (carouselHelper.$carousel.hasClass('slick-initialized')) {
                carouselHelper.$carousel.slick('setPosition');
            }
        }, 250));
    },

    /**
     * Initialize found carousels
     */
    init: function () {
        var that = this;
        $('.js-carousel').each(function () {
            // initialize carousel for each individual carousel wrappers
            that.carouselInit($(this));
        });
    }
};

module.exports = carousel;
