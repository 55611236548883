'use strict';

/* globals $ document */

var cache = {};

/**
 * Cache newsletter elements
 */
var initCache = function () {
    cache.$document = $(document);
    cache.contactus = {};
    cache.contactus.$form = cache.$document.find('.js-contactus-form');
    cache.contactus.$email = cache.contactus.$form.find('.js-contactus-email');
    cache.contactus.$send = cache.contactus.$form.find('button');
    cache.contactus.$counterMessage = cache.contactus.$form.find('#js-contactus_countermessage');
    cache.contactus.rawCounterMessage = cache.contactus.$counterMessage.attr('data-raw');
};

/**
 * Count remaining chars
 * @param {jQury Object} e - event object
 */
var countChars = function (e) {
    e.preventDefault();

    var $this = $(e.target),
        length = $this.val().length;

    cache.contactus.$counterMessage.html(cache.contactus.rawCounterMessage.replace(/\{0\}/, (1000 - length).toString()));
};

/**
 * Validate field on focus out
 * @param {jQury Object} e - event object
 */
var validateFields = function (e) {
    e.preventDefault();

    var $this = $(e.target);

    $this.valid();
};

/**
 * Bind events for contact us form
 */
var initEvents = function () {
    cache.contactus.$form.on('focusout', '.gl-input, .gl-select, .gl-textarea', validateFields);
    // check for custom events fired by jquery ui plugin for select elements
    cache.contactus.$form.find('.gl_select-custom select').on('selectmenuchange', validateFields);
    cache.contactus.$form.on('keyup change', '.gl-textarea.counter', countChars);

    // on page load count any characters available in the textarea
    cache.contactus.$form.find('.gl-textarea.counter').trigger('change');
};

module.exports.init = function () {
    initCache();
    initEvents();
};
